import {Moment} from 'moment';
import React, {ForwardedRef, forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {exportDistances} from "../../util/requestSender";
import {downloadFile, ResponseData, showDialog} from "@dvrd/dvr-controls";
import {createErrorMessage} from '../../util/utils';
import {ActivateHandle} from "../../util/interfaces";
import ExportView from "./exportView";
import moment from 'moment';
import {AppContext} from "../context/appContext";

const dateFormat = 'YYYY-MM-DD'

function ExportController(props: {}, ref: ForwardedRef<ActivateHandle>) {
    const context = useContext(AppContext);
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);

    function onActivate() {
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setStartDate(null);
        setEndDate(null);
        setLoading(false);
    }

    function onChangeDate(key: 'start' | 'end') {
        return function (value: Moment | null) {
            if (key === 'start') setStartDate(value ? moment(value) : value);
            else setEndDate(value ? moment(value) : value);
        }
    }

    function onSubmit() {
        const {vehicle} = context.vehicleContext;
        if (!vehicle) return showDialog('Je hebt nog geen voertuig geselecteerd');
        setLoading(true);
        exportDistances({
            data: {
                vehicle_id: vehicle.id,
                start_date: startDate?.format(dateFormat),
                end_date: endDate?.format(dateFormat)
            }, callback: (response: Response | ResponseData) => {
                setLoading(false);
                if (response instanceof Response) {
                    const header = response.headers.get('Content-Disposition');
                    let fileName = 'kilometer-registratie.xlsx';
                    if (header)
                        fileName =
                            header.substr(header.indexOf('filename=') + 'filename='.length, header.length);
                    response.blob().then(blob => {
                        downloadFile(blob, fileName);
                    });
                    onClose();
                } else
                    showDialog(createErrorMessage(response.message ?? 'Het downloaden van de kilometer registratie ' +
                        'is niet gelukt.'), 'Voorbeeld mislukt');
            }
        })
    }

    useImperativeHandle(ref, () => ({
        activate: onActivate,
    }));

    return (
        <ExportView onClose={onClose} onChangeDate={onChangeDate} onSubmit={onSubmit} startDate={startDate}
                    endDate={endDate} loading={loading} active={active}/>
    );
}

export default forwardRef<ActivateHandle, {}>(ExportController);