import './style/createDistance.scss';

import {
    AwesomeIcon,
    capitalize,
    ChangeFunction,
    ChangeKeyFunction,
    Checkbox,
    CloseButton,
    DvrdButton,
    DvrdDatePicker,
    DvrdInput,
    DvrdNumberInput,
    DvrdSelect,
    ElementPosition,
    Loader,
    SelectItemShape,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {FocusEventHandler, MouseEventHandler} from 'react';
import {DistanceErrors, DistancePurpose, EditMode} from "../../../util/interfaces";
import Distance from "../../../models/distanceModel";
import {Moment} from 'moment';
import classNames from 'classnames';

interface Props {
    onClose: MouseEventHandler;
    onSubmit: MouseEventHandler;
    onResetError: (key: keyof DistanceErrors) => FocusEventHandler;
    onChange: ChangeKeyFunction;
    onSelectCity: (city: string) => ChangeFunction;
    onClickDelete: MouseEventHandler;
    active: boolean;
    distance: Distance;
    loading: boolean;
    errors: DistanceErrors;
    cities: Set<string>;
    mode: EditMode;
}

const purposeItems: SelectItemShape[] = [
    {label: 'Zakelijk', value: DistancePurpose.BUSINESS},
    {label: 'Privé', value: DistancePurpose.PRIVATE}
];

export default function DistanceData(props: Props) {
    const {
        distance, errors, onResetError, loading, onClose, onSubmit, onChange, active, cities, onSelectCity, mode,
        onClickDelete
    } = props;

    function onChangeDate(value: Moment) {
        onChange('date')(value.format('YYYY-MM-DD'))
    }

    function renderBody() {
        return (
            <div className='body-container'>
                <Loader active={loading}/>
                {mode === EditMode.EDIT &&
                    <div className='row'>
                        <label>Laatst gewijzigd:<br/>{distance.updated}</label>
                        <AwesomeIcon name='trash-alt' className={classNames('btn-delete', loading && 'disabled')}
                                     onClick={loading ? undefined : onClickDelete}/>
                    </div>}
                <div className='row'>
                    <DvrdDatePicker value={distance.momentDate} label='Datum' dateFormat='DD-MM-YYYY'
                                    onChange={onChangeDate} error={errors.date} closeOnChange alwaysShowArrows/>
                    <DvrdSelect value={distance.purpose} items={purposeItems} onChange={onChange('purpose')}
                                label='Soort rit'/>
                </div>
                <div className='row'>
                    <DvrdNumberInput value={distance.startKm} onChange={onChange('start_km')} label='KM stand start'
                                     onFocus={onResetError('start_km')} error={errors.start_km} wholeNumbers
                                     ornaments={{element: <span>km</span>, placement: ElementPosition.RIGHT}} asNumber
                                     inputProps={{type: 'number'}} autoSelect/>
                    <DvrdNumberInput value={distance.endKm ? distance.endKm : ''} onChange={onChange('end_km')}
                                     label='KM stand eind' onFocus={onResetError('end_km')} error={errors.end_km}
                                     ornaments={{element: <span>km</span>, placement: ElementPosition.RIGHT}}
                                     inputProps={{type: 'number'}} wholeNumbers asNumber autoSelect/>
                </div>
                {renderCities()}
                <DvrdInput value={distance.driver} onChange={onChange('driver')} label='Bestuurder'
                           placeholder='Bestuurder'/>
                <DvrdInput value={distance.description} onChange={onChange('description')} label='Omschrijving'
                           inputProps={{rows: 3}} area/>
            </div>
        )
    }

    function renderCities() {
        return (
            <div className='cities-container'>
                <label className='cities-label'>Bezochte dorpen</label>
                {renderCity('Amstelhoek')}
                {renderCity('De Hoef')}
                {renderCity('Loenen')}
                {renderCity('Mijdrecht')}
                {renderCity('Vinkeveen')}
                {renderCity('Waverveen')}
                {renderCity('Wilnis')}
            </div>
        )
    }

    function renderCity(city: string) {
        const isSelected = cities.has(city);
        return <Checkbox checked={isSelected} onCheck={onSelectCity(city)} label={city}/>;
    }

    const action = mode === EditMode.NEW ? 'toevoegen' : 'wijzigen';
    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='create-distance default-popup'>
                <div className='header-container'>
                    <label className='header-title'>Rit {action}</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderBody()}
                <div className='footer-container'>
                    <DvrdButton onClick={onClose} label='Annuleren' disabled={loading} secondary/>
                    <DvrdButton onClick={onSubmit} label={capitalize(action)} disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}