import './style/exportView.scss';

import React, {MouseEventHandler} from 'react';
import {ChangeFunction, CloseButton, DvrdButton, DvrdDatePicker, Loader, WithBackground} from "@dvrd/dvr-controls";
import {Moment} from 'moment';

interface Props {
    onClose: MouseEventHandler;
    onChangeDate: (key: 'start' | 'end') => ChangeFunction<Moment | null>;
    onSubmit: MouseEventHandler;
    startDate: Moment | null;
    endDate: Moment | null;
    loading: boolean;
    active: boolean;
}

export default function ExportView(props: Props) {
    const {endDate, startDate, onChangeDate, loading, onSubmit, onClose, active} = props;

    function onClearDate(key: 'start' | 'end') {
        return function () {
            onChangeDate(key)(null);
        }
    }

    function renderBody() {
        return (
            <div className='body-container'>
                <Loader active={loading}/>
                <div className='field'>
                    <DvrdDatePicker onChange={onChangeDate('start')} value={startDate} label='Vanaf'
                                    dateFormat='DD-MM-YYYY'
                                    placeholder='dd-mm-jjjj' closeOnChange alwaysShowArrows/>
                    <CloseButton onClick={onClearDate('start')}/>
                </div>
                <div className='field'>
                    <DvrdDatePicker onChange={onChangeDate('end')} value={endDate} label='T/m' dateFormat='DD-MM-YYYY'
                                    placeholder='dd-mm-jjjj' closeOnChange alwaysShowArrows/>
                    <CloseButton onClick={onClearDate('end')}/>
                </div>
            </div>
        )
    }

    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='export-view default-popup'>
                <div className='header-container'>
                    <label className='header-title'>Ritten downloaden</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderBody()}
                <div className='footer-container'>
                    <DvrdButton onClick={onClose} label='Annuleren' secondary disabled={loading}/>
                    <DvrdButton onClick={onSubmit} label='Downloaden' disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}