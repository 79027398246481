import React, {ForwardedRef, forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {ActivateHandle, DistanceErrors, EditMode} from "../../../util/interfaces";
import Distance from "../../../models/distanceModel";
import {getLatestKM} from "../../../util/requestSender";
import {ResponseData, showDialog, showSnackbar, toMoment} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../../util/utils";
import {AppContext} from "../../context/appContext";
import DistanceData from "./distanceData";

interface Props {
    onSuccess: VoidFunction;
}

function DistanceDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const context = useContext(AppContext);
    const {onSuccess} = props;
    const [active, setActive] = useState(false);
    const [distance, setDistance] = useState(new Distance());
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<DistanceErrors>({date: null, end_km: null, start_km: null});
    const [cities, setCities] = useState<Set<string>>(new Set());
    const [mode, setMode] = useState<EditMode>(EditMode.NEW);

    function onActivate(_distance?: Distance) {
        setActive(true);
        if (_distance) {
            setDistance(_distance);
            setMode(EditMode.EDIT);
        } else loadLatest();
    }

    function onClose() {
        setActive(false);
        setDistance(new Distance());
        setCities(new Set());
        setErrors({date: null, end_km: null, start_km: null});
    }

    function onSelectCity(city: string) {
        return function () {
            const _cities = new Set(cities);
            if (_cities.has(city)) _cities.delete(city);
            else _cities.add(city);
            setCities(_cities);
        }
    }

    function onResetError(key: keyof DistanceErrors) {
        return function () {
            setErrors(Object.assign({}, errors, {[key]: null}));
        }
    }

    function onChange(key: string) {
        return function (value: any) {
            if (key in ['date', 'start_km', 'end_km']) onResetError(key as keyof DistanceErrors)();
            setDistance(distance.copy().setField(key, value));
        }
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        const _distance = addCities();
        if (mode === EditMode.NEW) onSave(_distance);
        else onUpdate(_distance);
    }

    function onSave(_distance: Distance) {
        const {vehicle} = context.vehicleContext;
        if (!vehicle) return;
        _distance.vehicleId = vehicle.id;
        _distance.save((distance: Distance, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onSuccess();
                onClose();
                showSnackbar('De rit is toegevoegd');
            } else showDialog(createErrorMessage(data.message ?? 'Het toevoegen van de rit is niet gelukt.'),
                'Toevoegen mislukt');
        });
    }

    function onUpdate(_distance: Distance) {
        _distance.update((distance: Distance, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onSuccess();
                onClose();
                showSnackbar('De rit is bijgewerkt');
            } else showDialog(createErrorMessage(data.message ?? 'Het bijwerken van de rit is niet gelukt.'),
                'Bijwerken mislukt');
        })
    }

    function onClickDelete() {
        showDialog('Weet je zeker dat je deze rit wilt verwijderen?', 'Rit verwijderen', ['Nee', {
            label: 'Ja',
            onClick: onConfirmDelete,
            primary: false
        }]);
    }

    function onConfirmDelete() {
        setLoading(true);
        distance.delete((distance: Distance, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onSuccess();
                onClose();
                showSnackbar('De rit is verwijderd');
            } else showDialog(createErrorMessage(data.message ?? 'Het verwijderen van de rit is niet gelukt.'),
                'Verwijderen mislukt');
        })
    }

    function addCities(): Distance {
        if (cities.size) {
            const citiesToAdd: Array<string> = [];
            const lowerDescription = distance.description.toLowerCase();
            cities.forEach((city: string) => {
                if (!lowerDescription.includes(city.toLowerCase())) citiesToAdd.push(city);
            })
            if (citiesToAdd.length) {
                const citiesString = citiesToAdd.join(", ");
                if (distance.description) distance.description += ` Dorpen: ${citiesString}`;
                else distance.description = citiesString;
            }
        }
        return distance;
    }

    function validateValues(): boolean {
        let valid = true;
        const _errors = Object.assign({}, errors);
        if (!distance.date) {
            _errors.date = 'Kies een datum';
            valid = false;
        }
        if (distance.endKm <= distance.startKm) {
            _errors.end_km = 'Ongeldig';
            valid = false;
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    function loadLatest() {
        const {vehicle} = context.vehicleContext;
        if (!vehicle) return;
        if (!loading) setLoading(true);
        getLatestKM({
            vehicle_id: vehicle.id,
            callback: (data: ResponseData) => {
                setLoading(false);
                if (data.success) {
                    const _distance = distance.copy();
                    _distance.startKm = data.latest;
                    _distance.driver = context.userContext.user?.firstname ?? '';
                    _distance.date = toMoment().format();
                    setDistance(_distance);
                    setMode(EditMode.NEW);
                }
            }
        })
    }

    useImperativeHandle(ref, () => ({
        activate: onActivate,
    }));

    return (
        <DistanceData onClose={onClose} onSubmit={onSubmit} onResetError={onResetError} onChange={onChange}
                      onSelectCity={onSelectCity} onClickDelete={onClickDelete} cities={cities} active={active}
                      distance={distance} mode={mode} loading={loading} errors={errors}/>
    )
}

export default forwardRef<ActivateHandle, Props>(DistanceDataController);