import './style/vehicleData.scss';

import {
    AwesomeIcon,
    ChangeKeyFunction,
    CloseButton,
    DvrdButton,
    DvrdInput,
    DvrdSelect,
    Loader,
    preventDefault,
    SelectItemShape,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {FocusEventHandler, MouseEventHandler} from 'react';
import {EditMode, VehicleErrors, VehicleType} from "../../../util/interfaces";
import Vehicle from "../../../models/vehicleModel";

interface Props {
    onClose: MouseEventHandler;
    onChange: ChangeKeyFunction;
    onResetError: (key: keyof VehicleErrors) => FocusEventHandler;
    onSubmit: MouseEventHandler;
    onClickDelete: MouseEventHandler;
    active: boolean;
    loading: boolean;
    vehicle: Vehicle;
    mode: EditMode;
    errors: VehicleErrors;
}

const typeItems: SelectItemShape[] = [
    {label: <label><AwesomeIcon name='car'/> Auto</label>, valueLabel: 'Auto', value: VehicleType.CAR},
    {label: <label><AwesomeIcon name='van-shuttle'/> Bus</label>, valueLabel: 'Bus', value: VehicleType.VAN},
    {label: <label><AwesomeIcon name='motorcycle'/> Motor</label>, valueLabel: 'Motor', value: VehicleType.MOTOR},
]

export default function VehicleData(props: Props) {
    const {vehicle, errors, onResetError, loading, onClose, onSubmit, onChange, active, mode, onClickDelete} = props;

    function renderBody() {
        return (
            <div className='body-container'>
                <Loader active={loading}/>
                {mode === EditMode.EDIT && (
                    <div className='delete-row'>
                        <AwesomeIcon name='trash-alt' className='btn-delete' onClick={onClickDelete}/>
                    </div>
                )}
                <form className='form' onSubmit={preventDefault}>
                    <DvrdSelect items={typeItems} value={vehicle.vehicleType} label='Soort voertuig'
                                onChange={onChange('vehicle_type')}/>
                    <DvrdInput value={vehicle.label} onChange={onChange('label')} label='Naam' error={errors.label}
                               onFocus={onResetError('label')}/>
                    <DvrdInput value={vehicle.licenceNumber} onChange={onChange('licence_number')} label='Kenteken'
                               error={errors.licence_number} onFocus={onResetError('licence_number')}/>
                </form>
            </div>
        )
    }

    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='vehicle-data default-popup'>
                <div className='header-container'>
                    <label className='header-title'>Voertuig {mode === EditMode.EDIT ? 'wijzigen' : 'toevoegen'}</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Sluiten' disabled={loading} secondary/>
                    <DvrdButton onClick={onSubmit} label='Opslaan' disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}