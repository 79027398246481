import {ResponseData, showDialog} from '@dvrd/dvr-controls';
import React, {ForwardedRef, forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {ActivateHandle} from '../../../util/interfaces';
import {validateTwoFactor} from "../../../util/requestSender";
import {createErrorMessage} from "../../../util/utils";
import ValidateTwoFactor from "./validateTwoFactor";

interface Props {
    onSuccess: VoidFunction;
}

function ValidateTwoFactorController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const {onSuccess} = props;
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const jwtToken = useRef<string | null>(null);

    function onActivate(_jwtToken: string) {
        jwtToken.current = _jwtToken;
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setToken('');
        setLoading(false);
        jwtToken.current = null;
    }

    function onChange(value: string) {
        setToken(value);
        if (value.length === 6) onSubmit(value)();
    }

    function onSubmit(_token?: string) {
        return function () {
            const jwt = jwtToken.current;
            if (!jwt) return;
            if (!_token) _token = token;
            validateTwoFactor({
                data: {
                    token: _token, jwt_token: jwt
                }, callback: (data: ResponseData) => {
                    if (data.success) {
                        onClose();
                        onSuccess();
                    } else showDialog(createErrorMessage(data.message ?? 'De code is ongeldig.'), 'Code ongeldig');
                }
            });
        }
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <ValidateTwoFactor onChange={onChange} onSubmit={onSubmit()} onClose={onClose} token={token} loading={loading}
                           active={active}/>
    )
}

export default forwardRef<ActivateHandle, Props>(ValidateTwoFactorController);