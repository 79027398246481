import './style/vehicleSelect.scss';

import {AwesomeIcon, CloseButton, DvrdButton} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useContext} from 'react';
import Vehicle from "../../models/vehicleModel";
import {AppContext} from "../context/appContext";
import {getVehicleIcon, getVehicleLabel} from "../../util/utils";
import classNames from 'classnames';

interface Props {
    onSelectVehicle: (vehicle: Vehicle) => MouseEventHandler;
    onEditVehicle: (vehicle: Vehicle) => MouseEventHandler;
    onClickCreate: MouseEventHandler;
    onClose: MouseEventHandler;
}

export default function VehicleSelect(props: Props) {
    const context = useContext(AppContext);
    const {onSelectVehicle, onClose, onClickCreate, onEditVehicle} = props;

    function renderBody() {
        const {vehicle, vehicles} = context.vehicleContext;
        const vehicleLabel = getVehicleLabel(vehicle, '-');
        const vehicleIcon = getVehicleIcon(vehicle);
        const remaining = vehicles.filter((veh: Vehicle) => veh.id !== vehicle?.id);
        return (
            <div className='body-container'>
                <h3>Actief voertuig:</h3>
                <label><AwesomeIcon name={vehicleIcon}/> {vehicleLabel}</label>
                <h3 className='vehicles-title'>Beschikbare voertuigen</h3>
                <div className='vehicles-container'>
                    {remaining.map(renderVehicle)}
                    {!remaining.length && <label className='empty-label'>Geen andere voertuigen beschikbaar</label>}
                </div>
            </div>
        )
    }

    function renderVehicle(vehicle: Vehicle, index: number) {
        const vehicleLabel = getVehicleLabel(vehicle);
        const vehicleIcon = getVehicleIcon(vehicle);
        return (
            <div key={index} className='vehicle-row' onClick={onSelectVehicle(vehicle)}>
                <label><AwesomeIcon name={vehicleIcon}/> {vehicleLabel}</label>
                <AwesomeIcon name='edit' onClick={onEditVehicle(vehicle)}/>
            </div>
        )
    }

    return (
        <div className='vehicle-select default-page'>
            <h2 className='header-title'>Voertuigselectie</h2>
            {renderBody()}
            <div className='footer-container'>
                <DvrdButton onClick={onClickCreate} label='Voertuig toevoegen'/>
                <DvrdButton onClick={onClose} label='Sluiten'/>
            </div>
        </div>
    )
}