import React, {useContext, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import Vehicle from "../../models/vehicleModel";
import {ActivateHandle} from '../../util/interfaces';
import {AppContext} from "../context/appContext";
import VehicleSelect from "./vehicleSelect";
import VehicleDataController from "./data/vehicleDataController";
import { stopPropagation } from '@dvrd/dvr-controls';

export default function VehicleSelectController() {
    const context = useContext(AppContext);
    const dataRef = useRef<ActivateHandle>(null);
    const navigate = useNavigate();

    function onSelectVehicle(vehicle: Vehicle) {
        return function () {
            context.onChangeContext({vehicle});
        }
    }

    function onEditVehicle(vehicle: Vehicle) {
        return function (evt: React.MouseEvent) {
            stopPropagation(evt);
            dataRef.current?.activate(vehicle);
        }
    }

    function onClickCreate() {
        dataRef.current?.activate();
    }

    function onClose() {
        navigate('/');
    }

    function onDataSuccess() {
        // TODO?
    }

    return (
        <>
            <VehicleSelect onSelectVehicle={onSelectVehicle} onEditVehicle={onEditVehicle} onClickCreate={onClickCreate}
                           onClose={onClose}/>
            <VehicleDataController ref={dataRef} onSuccess={onDataSuccess}/>
        </>
    );
}