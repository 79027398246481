import {BaseModel, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {Moment} from 'moment';
import {ModelResponse, ResponseData, toMoment} from "@dvrd/dvr-controls";
import {DistancePurpose} from "../util/interfaces";
import {createDistance, deleteDistance, getDistances, updateDistance} from "../util/requestSender";

interface Props {
    id?: string;
    created_at?: string;
    updated_at?: string;
    date?: string;
    start_km?: number;
    end_km?: number;
    purpose?: DistancePurpose;
    description?: string;
    driver?: string;
    user_id?: string;
    vehicle_id?: string;
}

interface Fields {
    id: string;
    created_at: string;
    updated_at: string;
    date: string;
    start_km: number;
    end_km: number;
    purpose: DistancePurpose;
    description: string;
    driver: string;
    user_id: string;
    vehicle_id: string;
}

const fields: Fields = {
    id: '',
    created_at: '',
    updated_at: '',
    date: '',
    start_km: 0,
    end_km: 0,
    purpose: DistancePurpose.BUSINESS,
    description: '',
    driver: '',
    user_id: '',
    vehicle_id: '',
};

const fieldsDefinition: { [key in keyof Fields]: FieldDefinition } = {
    id: createFieldDef(ModelFieldType.STRING),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    date: createFieldDef(ModelFieldType.STRING),
    start_km: createFieldDef(ModelFieldType.NUMBER),
    end_km: createFieldDef(ModelFieldType.NUMBER),
    description: createFieldDef(ModelFieldType.STRING),
    driver: createFieldDef(ModelFieldType.STRING),
    purpose: createFieldDef(ModelFieldType.STRING, DistancePurpose.BUSINESS),
    user_id: createFieldDef(ModelFieldType.STRING),
    vehicle_id: createFieldDef(ModelFieldType.STRING),
};

const ignoreProps: (keyof Fields)[] = [];

export default class Distance extends BaseModel<Props, Fields> {
    static getAll = (params: {
        vehicle_id: string,
        limit: number,
        page: number
    }, callback: ModelResponse<Distance[]>) => {
        getDistances({
            data: params, callback: (data: ResponseData) => {
                const distances: Array<Distance> = data.success ? data.distances.map((dist: any) => new Distance(dist))
                    : [];
                callback(distances, data.success, data);
            }
        })
    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get id(): string {
        return this.fields.id;
    }

    set id(id: string) {
        this.fields.id = id;
    }

    get createdAt(): string {
        return this.fields.created_at;
    }

    set createdAt(created_at: string) {
        this.fields.created_at = created_at;
    }

    get createdAtMoment(): Moment {
        return toMoment(this.createdAt);
    }

    get updatedAt(): string {
        return this.fields.updated_at;
    }

    set updatedAt(updated_at: string) {
        this.fields.updated_at = updated_at;
    }

    get updatedAtMoment(): Moment {
        return toMoment(this.updatedAt);
    }

    get updated(): string {
        const {updatedAt, createdAtMoment, updatedAtMoment} = this;
        const value = !!updatedAt ? updatedAtMoment : createdAtMoment;
        return value.format('DD-MM-YYYY HH:mm');
    }

    get date(): string {
        return this.fields.date;
    }

    set date(date: string) {
        this.fields.date = date;
    }

    get momentDate(): Moment {
        return toMoment(this.fields.date);
    }

    get startKm(): number {
        return this.fields.start_km;
    }

    set startKm(start_km: number) {
        this.fields.start_km = start_km;
    }

    get endKm(): number {
        return this.fields.end_km;
    }

    set endKm(end_km: number) {
        this.fields.end_km = end_km;
    }

    get kmTraveled(): number {
        return this.fields.end_km - this.fields.start_km;
    }

    get purpose(): DistancePurpose {
        return this.fields.purpose;
    }

    set purpose(purpose: DistancePurpose) {
        this.fields.purpose = purpose;
    }

    get translatedPurpose(): string {
        return {[DistancePurpose.PRIVATE]: 'Privé', [DistancePurpose.BUSINESS]: 'Zakelijk'}[this.purpose];
    }

    get description(): string {
        return this.fields.description;
    }

    set description(description: string) {
        this.fields.description = description;
    }

    get driver(): string {
        return this.fields.driver;
    }

    set driver(driver: string) {
        this.fields.driver = driver;
    }

    get userId(): string {
        return this.fields.user_id;
    }

    set userId(user_id: string) {
        this.fields.user_id = user_id;
    }

    get vehicleId(): string {
        return this.fields.vehicle_id;
    }

    set vehicleId(id: string) {
        this.fields.vehicle_id = id;
    }

    copy = (): Distance => {
        const fields: Fields = {
            id: this.id, purpose: this.purpose, end_km: this.endKm, driver: this.driver, date: this.date,
            created_at: this.createdAt, description: this.description, start_km: this.startKm,
            updated_at: this.updatedAt, user_id: this.userId, vehicle_id: this.vehicleId,
        };
        return new Distance(fields);
    }

    save = (callback: ModelResponse<Distance>) => {
        createDistance({
            data: {
                description: this.description, driver: this.driver, end_km: this.endKm, purpose: this.purpose,
                start_km: this.startKm, date: this.momentDate.format('YYYY-MM-DD'), vehicle_id: this.vehicleId
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.distance);
                callback(this, data.success, data);
            }
        });
    };

    update = (callback: ModelResponse<Distance>) => {
        updateDistance({
            data: {
                start_km: this.startKm, purpose: this.purpose, end_km: this.endKm, driver: this.driver,
                description: this.description, distance_id: this.id, distance_date: this.date
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.distance);
                callback(this, data.success, data);
            }
        })
    };

    delete = (callback: ModelResponse<Distance>) => {
        deleteDistance({
            distance_id: this.id, callback: (data: ResponseData) => {
                callback(this, data.success, data);
            }
        })
    };

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            // TODO
        }
    };
}