import './style/distanceOverview.scss';

import {AwesomeIcon, DvrdButton, Link, Loader, remToPx} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useMemo} from 'react';
import Distance from "../../models/distanceModel";
import LoadableList, {ListLoader, RenderRowProps} from "../misc/loadableList";
import {DistanceInfo, DistancePurpose} from "../../util/interfaces";
import classNames from 'classnames';

interface Props {
    onClickDistance: (distance: Distance) => MouseEventHandler;
    onCreateDistance: MouseEventHandler;
    onLoadMore: ListLoader;
    onClickExport: MouseEventHandler;
    onChangeInfoYear: (year: number) => MouseEventHandler;
    distances: Array<Distance>;
    loading: boolean;
    loadingMore: boolean;
    total: number;
    distanceInfo: DistanceInfo;
    infoYear: number;
}

export default function DistanceOverview(props: Props) {
    const {
        distances, onClickDistance, onCreateDistance, loading, loadingMore, onLoadMore, total, onClickExport,
        distanceInfo, infoYear, onChangeInfoYear
    } = props;
    const hasMore = useMemo(() => {
        return distances.length < total
    }, [distances, total]);

    function renderDistanceInfo() {
        return (
            <div className='distance-info-container'>
                <label className='info-title'>Gereden afstanden</label>
                <div className='year-select-container'>
                    <AwesomeIcon name='chevron-left' className='year-select' onClick={onChangeInfoYear(infoYear - 1)}/>
                    <label className='info-year'>{infoYear}</label>
                    <AwesomeIcon name='chevron-right' className='year-select' onClick={onChangeInfoYear(infoYear + 1)}/>
                </div>
                <div className='distance-info'>
                    <label><AwesomeIcon name='suitcase'
                                        className='icon purpose'/> Zakelijk: {distanceInfo[DistancePurpose.BUSINESS] ?? 0} km</label>
                    <label><AwesomeIcon name='user'
                                        className='icon driver'/> Privé: {distanceInfo[DistancePurpose.PRIVATE] ?? 0} km</label>
                </div>
            </div>
        )
    }

    function renderDistances() {
        return (
            <div className='distances-container'>
                <LoadableList onLoadMore={onLoadMore} renderRow={renderDistanceRow} hasMore={hasMore}
                              isLoadingMore={loadingMore} items={distances} itemSize={remToPx(5.5)}/>
            </div>
        )
    }

    function renderDistanceRow({style, index}: RenderRowProps) {
        const isLoaded = !hasMore || index < distances.length;
        if (!isLoaded) return (
            <div style={style} className='list-row'>
                <div/>
                <label>Product laden...</label>
                <label/>
            </div>
        );
        const distance = distances[index];
        const isPrivate = distance.purpose === DistancePurpose.PRIVATE;
        return (
            <div style={style} className={classNames('list-row', isPrivate && 'private', 'click')}
                 onClick={onClickDistance(distance)}>
                <label><AwesomeIcon name='calendar-days'
                                    className='icon calendar'/> {distance.momentDate.format('DD-MM-YYYY')}</label>
                <label><AwesomeIcon name='suitcase' className='icon purpose'/> {distance.translatedPurpose}</label>
                <label><AwesomeIcon name='user' className='icon driver'/> {distance.driver || '-'}</label>
                <label><AwesomeIcon name='car-side' className='icon start'/> {distance.startKm} km</label>
                <label><AwesomeIcon name='car-side' className='icon end'/> {distance.endKm} km</label>
                <label><AwesomeIcon name='arrows-left-right'
                                    className='icon distance'/> {distance.kmTraveled} km</label>
                <label className='description-label'><AwesomeIcon name='pen'
                                                                  className='icon description'/> {distance.description || '-'}
                </label>
            </div>
        )
    }

    return (
        <div className='distance-overview default-page'>
            <Loader active={loading}/>
            {renderDistanceInfo()}
            {renderDistances()}
            <div className='footer-container'>
                <DvrdButton onClick={onCreateDistance} label='Rit toevoegen'
                            ornaments={{element: <AwesomeIcon name='plus'/>}} customBaseColor='#29A71A'
                            customContrastColor='#ffffff' disabled={loading}/>
                <Link onClick={onClickExport}>Downloaden</Link>
            </div>
        </div>
    )
}