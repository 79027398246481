import React, {ForwardedRef, forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {ActivateHandle, ChangeContextConfig, EditMode, VehicleErrors} from "../../../util/interfaces";
import {AppContext} from "../../context/appContext";
import Vehicle from "../../../models/vehicleModel";
import {capitalize, ResponseData, showDialog, showSnackbar} from '@dvrd/dvr-controls';
import {createErrorMessage, getVehicleLabel} from "../../../util/utils";
import VehicleData from "./vehicleData";

interface Props {
    onSuccess: VoidFunction;
}

function VehicleDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const context = useContext(AppContext)
    const {onSuccess} = props;
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState<Vehicle>(new Vehicle());
    const [mode, setMode] = useState<EditMode>(EditMode.NEW);
    const [errors, setErrors] = useState<VehicleErrors>({label: null, licence_number: null})

    function onActivate(vehicle?: Vehicle) {
        if (vehicle) {
            setVehicle(vehicle.clone());
            setMode(EditMode.EDIT);
        } else setMode(EditMode.NEW);
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setVehicle(new Vehicle());
        setMode(EditMode.NEW);
        setLoading(false);
    }

    function onChange(key: string) {
        return function (value: any) {
            if (key === 'label' || key === 'licence_number') onResetError(key)();
            setVehicle(vehicle.clone().setField(key, value));
        }
    }

    function onResetError(key: keyof VehicleErrors) {
        return function () {
            setErrors(Object.assign({}, errors, {[key]: null}));
        }
    }

    function onClickDelete() {
        setLoading(true);
        vehicle.delete((vehicle: Vehicle, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (!success) return showDialog(createErrorMessage(data.message ??
                'Het verwijderen van het voertuig is niet gelukt.'), 'Verwijderen mislukt');
            const vehicles = context.vehicleContext.vehicles.filter((veh: Vehicle) => veh.id !== vehicle.id);
            const contextVehicle = context.vehicleContext.vehicle;
            context.onChangeContext({vehicles, vehicle: contextVehicle?.id === vehicle.id ? null : contextVehicle});
            showSnackbar(`${getVehicleLabel(vehicle)} is verwijderd.`);
            onSuccess();
            onClose();
        });
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        if (mode === EditMode.NEW) vehicle.save(responseCallback);
        else vehicle.update(responseCallback);
    }

    function responseCallback(vehicle: Vehicle, success: boolean, data: ResponseData) {
        setLoading(false);
        if (!success) {
            const label = mode === EditMode.EDIT ? 'wijzigen' : 'toevoegen';
            return showDialog(createErrorMessage(data.message ?? `Het ${label} is niet gelukt.`),
                `${capitalize(label)} mislukt`);
        }
        const vehicles = context.vehicleContext.vehicles.slice();
        let contextChangeData: ChangeContextConfig = {};
        if (mode === EditMode.EDIT) {
            for (let i = 0; i < vehicles.length; i++) {
                if (vehicles[i].id === vehicle.id) {
                    vehicles.splice(i, 1, vehicle);
                    break;
                }
            }
            contextChangeData.vehicles = vehicles;
        } else vehicles.push(vehicle);
        const contextVehicle = context.vehicleContext.vehicle;
        if (!contextVehicle || contextVehicle.id === vehicle.id) contextChangeData.vehicle = vehicle;
        context.onChangeContext(contextChangeData);
        showSnackbar(`${getVehicleLabel(vehicle)} is toegevoegd`);
        onSuccess();
        onClose();
    }

    function validateValues(): boolean {
        const _errors = Object.assign({}, errors);
        let valid: boolean = true;
        if (!vehicle.label) {
            valid = false;
            _errors.label = 'Vul de naam in';
        }
        if (!vehicle.licenceNumber) {
            valid = false;
            _errors.licence_number = 'Vul het kenteken in';
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    useImperativeHandle(ref, () => ({
        activate: onActivate,
    }));

    return (
        <VehicleData onClose={onClose} onChange={onChange} onResetError={onResetError} onSubmit={onSubmit}
                     onClickDelete={onClickDelete} active={active} loading={loading} vehicle={vehicle} mode={mode}
                     errors={errors}/>
    )
}

export default forwardRef<ActivateHandle, Props>(VehicleDataController);