import React, {useContext, useEffect, useRef, useState} from 'react';
import {ResponseData, showDialog, toMoment} from '@dvrd/dvr-controls';
import Distance from "../../models/distanceModel";
import {createErrorMessage} from "../../util/utils";
import DistanceOverview from "./distanceOverview";
import {ActivateHandle, DistanceInfo} from "../../util/interfaces";
import DistanceDataController from "./data/distanceDataController";
import ExportController from "../export/exportController";
import {getDistanceInfo} from "../../util/requestSender";
import {AppContext} from "../context/appContext";

const LIMIT = 20;
const dateFormat = 'YYYY-MM-DD';

export default function DistanceOverviewController() {
    const context = useContext(AppContext);
    const [distances, setDistances] = useState<Array<Distance>>([]);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(0);
    const [distanceInfo, setDistanceInfo] = useState<DistanceInfo>({BUSINESS: 0, PRIVATE: 0});
    const [infoYear, setInfoYear] = useState(toMoment().year());
    const dataRef = useRef<ActivateHandle>(null);
    const exportRef = useRef<ActivateHandle>(null);

    function onChangeInfoYear(year: number) {
        return function () {
            setInfoYear(year);
        }
    }

    function onClickExport() {
        exportRef.current?.activate();
    }

    function onClickDistance(distance: Distance) {
        return function () {
            dataRef.current?.activate(distance);
        }
    }

    function onCreateDistance() {
        dataRef.current?.activate();
    }

    function onLoadMore(startIndex: number) {
        return new Promise<void>((resolve, reject) => {
            const {vehicle} = context.vehicleContext;
            if (!vehicle) return reject();
            setLoadingMore(true);
            const page = Math.floor(startIndex / LIMIT);
            Distance.getAll({
                vehicle_id: vehicle.id, limit: LIMIT, page
            }, (_distances: Array<Distance>, success: boolean, data: ResponseData) => {
                setLoadingMore(false);
                if (!success) {
                    showDialog(createErrorMessage(data.message ?? 'Het laden van meer registraties is niet gelukt.'),
                        'Laden mislukt');
                    return reject();
                }
                setDistances(distances.concat(_distances));
            })
        })
    }

    function loadDistances() {
        const {vehicle} = context.vehicleContext;
        if (!vehicle) return;
        if (!loading) setLoading(true);
        Distance.getAll({vehicle_id: vehicle.id, limit: LIMIT, page: 0},
            (distances: Array<Distance>, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) {
                    setDistances(distances);
                    setTotal(data.total);
                } else showDialog(createErrorMessage(data.message ?? 'Het laden van de registraties is niet gelukt.'),
                    'Laden mislukt')
            });
    }

    function loadDistanceInfo() {
        const {vehicle} = context.vehicleContext;
        if (!vehicle) return;
        const startDate = toMoment().set({'month': 0, 'day': 1, 'year': infoYear});
        const endDate = toMoment().set({'month': 11, 'day': 31, 'year': infoYear});
        setLoading(true);
        getDistanceInfo({
            data: {
                vehicle_id: vehicle.id,
                start_date: startDate.format(dateFormat),
                end_date: endDate.format(dateFormat)
            }, callback: (data: ResponseData) => {
                setLoading(false);
                if (data.success) setDistanceInfo(data.distance_info);
                else
                    showDialog(createErrorMessage(data.message ?? 'Het laden van de gereisde afstand is niet gelukt.'),
                        'Laden mislukt');
            }
        })
    }

    useEffect(() => {
        loadDistances();
    }, [context.vehicleContext.vehicle]);

    useEffect(() => {
        loadDistanceInfo();
    }, [infoYear, context.vehicleContext.vehicle]);

    return (
        <>
            <DistanceOverview onClickDistance={onClickDistance} onCreateDistance={onCreateDistance}
                              onLoadMore={onLoadMore} onClickExport={onClickExport} onChangeInfoYear={onChangeInfoYear}
                              distances={distances} distanceInfo={distanceInfo} infoYear={infoYear} loading={loading}
                              loadingMore={loadingMore} total={total}/>
            <DistanceDataController ref={dataRef} onSuccess={loadDistances}/>
            <ExportController ref={exportRef}/>
        </>
    )
}