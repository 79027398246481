import {BaseModel, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {Moment} from 'moment';
import {ModelResponse, ResponseData, toMoment} from "@dvrd/dvr-controls";
import {VehicleType} from "../util/interfaces";
import User from "./userModel";
import Distance from "./distanceModel";
import {createVehicle, deleteVehicle, getVehicle, getVehicles, updateVehicle} from "../util/requestSender";

interface Props {
    id?: string;
    created_at?: string;
    updated_at?: string;
    label?: string;
    licence_number?: string;
    vehicle_type?: VehicleType;
    user_id?: string;
    user?: User | any;
    distances?: Array<Distance | any>;
}

interface Fields {
    id: string;
    created_at: string;
    updated_at: string;
    label: string;
    licence_number: string;
    vehicle_type: VehicleType;
    user_id: string;
    user: User | null;
    distances: Array<Distance>;
}

const fields: Fields = {
    id: '',
    created_at: '',
    updated_at: '',
    label: '',
    licence_number: '',
    vehicle_type: VehicleType.VAN,
    user_id: '',
    user: null,
    distances: new Array<Distance>(),
};

const fieldsDefinition: { [key in keyof Omit<Fields, 'user' | 'distances'>]: FieldDefinition } = {
    id: createFieldDef(ModelFieldType.STRING),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    label: createFieldDef(ModelFieldType.STRING),
    licence_number: createFieldDef(ModelFieldType.STRING),
    user_id: createFieldDef(ModelFieldType.STRING),
    vehicle_type: createFieldDef(ModelFieldType.STRING, VehicleType.VAN),
};

const ignoreProps: (keyof Fields)[] = [];

export default class Vehicle extends BaseModel<Props, Fields> {
    static get = (id: string, callback: ModelResponse<Vehicle | null>) => {
        getVehicle({
            vehicle_id: id, callback: (data: ResponseData) => {
                const vehicle = data.success ? new Vehicle(data.vehicle) : null;
                callback(vehicle, data.success, data);
            }
        });
    };

    static getAll = (callback: ModelResponse<Vehicle[]>) => {
        getVehicles({
            callback: (data: ResponseData) => {
                const vehicles: Array<Vehicle> = data.success ? data.vehicles.map((veh: any) => new Vehicle(veh)) : [];
                callback(vehicles, data.success, data);
            }
        });
    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get id(): string {
        return this.fields.id;
    }

    set id(id: string) {
        this.fields.id = id;
    }

    get createdAt(): string {
        return this.fields.created_at;
    }

    set createdAt(created_at: string) {
        this.fields.created_at = created_at;
    }

    get createdAtMoment(): Moment {
        return toMoment(this.createdAt);
    }

    get updatedAt(): string {
        return this.fields.updated_at;
    }

    set updatedAt(updated_at: string) {
        this.fields.updated_at = updated_at;
    }

    get updatedAtMoment(): Moment {
        return toMoment(this.updatedAt);
    }

    get label(): string {
        return this.fields.label;
    }

    set label(label: string) {
        this.fields.label = label;
    }

    get licenceNumber(): string {
        return this.fields.licence_number;
    }

    set licenceNumber(licence_number: string) {
        this.fields.licence_number = licence_number;
    }

    get vehicleType(): VehicleType {
        return this.fields.vehicle_type;
    }

    set vehicleType(vehicle_type: VehicleType) {
        this.fields.vehicle_type = vehicle_type;
    }

    get userId(): string {
        return this.fields.user_id;
    }

    set userId(user_id: string) {
        this.fields.user_id = user_id;
    }

    get user(): User | null {
        return this.fields.user;
    }

    set user(user: User | null) {
        this.fields.user = user;
        if (user) this.fields.user_id = user.id;
    }

    get distances(): Array<Distance> {
        return this.fields.distances;
    }

    set distances(distances: Array<Distance>) {
        this.fields.distances = distances;
    }

    clone = (): Vehicle => {
        const fields: Fields = {
            id: this.id, vehicle_type: this.vehicleType, licence_number: this.licenceNumber, distances: this.distances,
            created_at: this.createdAt, label: this.label, updated_at: this.updatedAt, user_id: this.userId,
            user: this.user
        }
        return new Vehicle(fields);
    };

    save = (callback: ModelResponse<Vehicle>) => {
        createVehicle({
            data: {
                label: this.label, vehicle_type: this.vehicleType, licence_number: this.licenceNumber
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.vehicle);
                callback(this, data.success, data);
            }
        });
    };

    update = (callback: ModelResponse<Vehicle>) => {
        updateVehicle({
            data: {
                label: this.label, vehicle_type: this.vehicleType, vehicle_id: this.id,
                licence_number: this.licenceNumber
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.vehicle);
                callback(this, data.success, data);
            }
        })
    };

    delete = (callback: ModelResponse<Vehicle>) => {
        deleteVehicle({
            vehicle_id: this.id, callback: (data: ResponseData) => {
                callback(this, data.success, data);
            }
        })
    };

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            if (props.user)
                this.user = props.user instanceof User ? props.user : new User(props.user);
            if (props.distances) {
                const distances: Array<Distance> = new Array<Distance>();
                for (const dist of props.distances) {
                    const distance = dist instanceof Distance ? dist : new Distance(dist);
                    distances.push(distance);
                }
                this.fields.distances = distances;
            }
        }
    };
}