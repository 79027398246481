import './style/header.scss'

import React, {useContext} from 'react';
import {AppContext} from "../context/appContext";
import {AwesomeIcon} from '@dvrd/dvr-controls';
import {getVehicleIcon, getVehicleLabel} from "../../util/utils";
import {useNavigate} from 'react-router-dom';

export default function Header() {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    function onClickVehicle() {
        navigate('/voertuigen');
    }

    function renderVehicle() {
        if (!context.userContext.user) return null;
        const {vehicle} = context.vehicleContext;
        return (
            <div className='vehicle-row' onClick={onClickVehicle}>
                <label><AwesomeIcon name={getVehicleIcon(vehicle)}/> {getVehicleLabel(vehicle)}</label>
            </div>
        )
    }

    return (
        <div className='application-header'>
            <label>Kilometer registratie</label>
            {renderVehicle()}
        </div>
    )
}